<script>
export default {
  beforeMount() {
    this.$nextTick(async () => {
      const token = this.$route.params.refresh;
      if (token && token.length > 30) {
        await this.$http
          .post("/auth/token/refresh/", { refresh: token })
          .then((response) => {
            this.$store.commit("updateAccess", response.data.access);
            localStorage.setItem("refresh", token);
            this.$store.dispatch("loadUser").then((user) => {
              this.$store.commit("updateUser", user);
              this.$router.push({ name: "Teachers" });
            });
          })
          .catch(() => {
            this.$store.dispatch("logout");
            this.$toast.error(
              "Erreur votre jeton d'authentification a expire!"
            );
            this.$router.push({ name: "Home" });
          });
      } else this.$router.push({ name: "Logout" });
    });
  },
};
</script>
